@use "../../../../scss/variables.scss";
@import "../../../../styles/inputIcon.scss";

.searchBox {
    background-color: #F4F4F4;
    border-radius: 20px;
    @include inputIcon(
        $inputSelector: input,
        $leftMargin: 40px
    )
}

.orderBySelect {
    border: 1px solid;
    border-color: variables.$primary;
    color: variables.$primary;
    border-radius: 5px;
    select {
        color: variables.$primary;
    }
}
