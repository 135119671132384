@use "../../../../scss/variables.scss";

.input {
    border-radius: 15px !important;
    -webkit-appearance: none;
}

.googleImportCard {
    cursor: pointer;
    box-shadow: 0 3px 2px 1px rgb(220, 220, 220);
}

.googleImportCard:hover {
    background-color: #E0E0E2;
    box-shadow: 0 3px 2px 2px rgb(220, 220, 220);
}

.googleImportHeader {
    font-weight: 1000;
    font-size: 1.25rem;
}

.googleImportBody {
    color: rgb(120, 120, 120);
}

.assignmentPanel {
    min-height: calc(100vh - 104px - 2rem);
    display: flex;
    flex-direction: column;
}

.disabled {
    pointer-events: none;
    background: darkgray !important;
}

.assessmentViewOptionsContainer {
    display: block !important;
}

.assessmentViewOption {
    width: 10rem;
}

// This two classes are for assessment optons container and item for create assignment view
.assessmentViewForAssignment {
    display: flex !important;
    flex-wrap: wrap;
}

.assessmentViewOptionAssignment {
    flex: 1 1 100% !important;
    margin-top: 3px;
}

.termBgUnselect {
    background-color: #a3aafa !important;
    font-weight: normal;
}

.termBgSelect {
    background-color: variables.$theme-color-2 !important;
    font-weight: bold;
}

.saveBtnContainer {
    display: flex;
    justify-content: flex-end;
}

.saveBtn {
    width: auto !important;
    float: right;
}

@media screen and (max-width: 575px) {
    .saveBtnContainer {
        display: flex;
        justify-content: center;
    }

    .saveBtn {
        width: 100% !important;
        float: none;
    }
}

@media screen and (max-width: 400px) {
    .assessmentViewOptionsContainer {
        display: flex !important;
        flex-wrap: wrap;
    }

    .assessmentViewOptionItem {
        flex: 1 1 100% !important;
    }
}

@media screen and (max-width: 500px) {
    .assessmentViewOptionsContainer {
        display: flex !important;
    }

    .assessmentViewOptionItem {
        flex: 1 1 100% !important;
    }
}

@media screen and (max-width: 1154px) and (min-width: 768px) {
    .assessmentViewOptionsContainer {
        display: flex !important;
        flex-wrap: wrap;
    }

    .assessmentViewOptionItem {
        flex: 1 1 100% !important;
    }
}
