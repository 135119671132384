.signup-container {
    background-image: url('STEM-background.png');
}

@media screen and (min-width: 1500px) {
    .signup-container {
        height: 100vh;
    }
}

#signupPage {
    .faded-vertical-line {
        flex-grow: 1;
        height: 100px;
        width: 2px;
        background-color: rgba(236, 105, 105, 0.3);
        margin: 0 10px;
    }
    
    @media screen and (max-width: 768px) {
        .faded-vertical-line {
            height: 1px;
        }
    }
    @media screen and (max-width: 404px) {
        .enter-details-label {
            width: 80% !important;
        }
    }
    @media screen and (min-width: 508px) {
        .enter-details-label {
            width: 60% !important;
        }
    }
    .google-button {
        background-color: #ffffff;
        border: 1px solid #b5b5b8;
        color: #111;
        font-family: Roboto,sans-serif !important;
        font-size: 16px;
    }
    .google-button:hover {
        background-color: #e6e6e6;
    }
    @media screen and (min-width: 768px) {
        .create-account-btn {
            width: 50% !important;
        }
    }
}

  