@use "../../scss/variables.scss";

.background {
    background-image: url('STEM-background-5.png');
}

.textbookCardBody {
    cursor: pointer;
}

.textbookCardBody:hover {
    background-color: #f8f9fa;
}

.downUpArrow {
    position: absolute;
    right: 2%;
}
