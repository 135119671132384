@use "sass:color";

.infoText {
    color: gray;
    font-size: 14px;
}

.primaryText {
    color: #2F2E41;
    font-size: 1.1em;
    font-weight: bolder;
}

.secondaryText {
    color: #2F2E41;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.tertiaryText {
    color: #2F2E41;
    font-size: 10px;
}

.clearText {
    color: #681A15;
    font-size: 14px;
    margin-bottom: 0px;
    cursor: pointer;
}

.clearText:hover {
    color: color.scale(#681A15, $lightness: 30%);
}

.bgDesaturated, .bgDesaturated>button {
    background-color: #E0E0E2 !important;
    height: 100% !important;
}

.bgLightBlue, .bgLightBlue>button {
    background-color: rgba(#3F4BBC80,0.09) !important;
    height: 100% !important;
}

.indent {
    margin-left: 65px;
}

.tour-highlight {
    border: 2px solid green;
    box-shadow: 0 0 10px green;
    z-index: 1000;
    background-color: black;
}

.viewSimBtn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    display: flex !important; /* btn class isn't vertically centering the text when href is added - making it into a link instead of button */
    align-items: center;
    margin-right: 10px; /* margin right is needed to have round the button corners. Otherwise, Col will take full space and rounded corner disappear. */
}

.buyModuleBtn {
    border-color: #E0E0E2 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    display: flex !important;
    align-items: center;
    background-color: gray !important;
    margin-right: 10px;
}

.icon {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.accordionImg {
    position: absolute;
    max-width:65px;
    overflow: hidden;
    height:100%;
    left:0;
    top:0;
}
.accordionImg>img {
    height:100%;
}

.resultStar {
    color: gold;
    font-size: 20px;
    padding-right: 10px;
}

@media (min-width: 768px) {
    .searchCol {
        flex-basis: 250px;
        border-right: solid 2px rgba(gray,0.3);
    }
}

@media (max-width: 576px) {
    .viewSimBtn > span {
        display: none;
    }
    .buyModuleBtn > span {
        display: none;
    }
}

@media (max-width: 576px) {
    .icon {
        margin-right: 0rem;
        margin-left: 0rem;
    }
}

.dropdownScroll {
    max-height: 300px;
    overflow-y: scroll;
}

/* Grade Filter Horizontal Bar */
.grade {
    width: 3.6rem;
}

.gradeBgColor {
    background-color: #8d97fc !important;
}

@media (max-width: 1024px) {
    .grade {
        width: 3.2rem;
        font-size: small;
    }
}
