.backBtnContainer {
    position: absolute;
    left: 50px;
}

@media screen and (max-width: 991px) {
    .backBtnContainer {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translateX(-50%);
    }

    .headerContainer {
        margin-top: 50px;
    }
}

.row {
    width: 65%;
}

@media screen and (max-width: 992px) {
    .row {
        width: 80%;
    }
}

.tab {
    border: 1px solid lightslategray !important;
    box-shadow: 2px 2px lightslategray !important;
}

.tab:hover {
    box-shadow: 4px 5px lightslategray !important;
    transition: box-shadow 0.1s ease-in-out;
}

.activeTab {
    border: none;
    box-shadow: 4px 5px lightgray !important;
}

.tableRowBackground:hover {
    background-color: rgba(0, 0, 0, 0.075); // color from bootstrap table row when hover
}

.childTable {
    opacity: 0 !important;
    transition: opacity 0.8s ease;
}

.childTable.disappear {
    opacity: 0 !important;
}

.childTable.appear {
    opacity: 1 !important;
}
