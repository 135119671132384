@use "../../../scss/variables.scss";

.lessonTable > div > p {
	text-align: left !important;
}
.lessonTable table {
	margin: auto auto auto 0 !important;
}
.lessonTable > div > table {
	text-align: left !important;
}
.lessonTable > div {
	text-align: left !important;
}
.lessonTable td {
	text-align: center;
	vertical-align: middle;
}
.lessonTable td input{
	width: 100%;
}
.lessonTable div, .lessonTable input[type="text"] {
	width: 100%;
	height: 100%;
	padding: 0px;
}
@media (max-width: 771px) {
	.lessonTable div {
		max-width: 100% !important;
	}
}
.lessonTable textarea {
	resize: none;
}
.bgCheckbox {
	width: 1.4em !important;
	height: 1.4em !important;
}
.heightFit {
	margin-inline: 2px;
	height: -moz-fit-content;
	height: fit-content;
}
.lessonLink {
	text-decoration: none;
	color: variables.$theme-color-2; /* theme color */
}
.lessonInput {
	margin-top: 18px;
	margin-bottom: 12px;
	width: 100%;
}
.lessonInputLg {
	min-height: 100px;
}
.lessonImage, .lessonImageSm {
	max-width: 90%;
	max-height: 500px;
}
.lessonImageSm {
	height: 300px;
}

.listShadow:hover {
	transition: box-shadow ease-in-out 0.2s;
	z-index: 10;
	box-shadow: 0 0.4rem 0.8rem rgb(0 0 0 / 20%) !important;
}

.elementary {
    font-family: 'Tahoma', 'Arial', 'Open Sans', sans-serif;
    font-size: 1.2rem;
}

.innerModal {
	background-color: #00000065;
	z-index: 1055;
	>div	{
		width: 330px;
		box-shadow: 0 2px 15px rgba(0,0,0,0.15);
		top: 18%;
	}
}

.lessonTabs {
	display: flex;
	width: 100%; 
	flex-direction: column;
	flex: 1;
}

.lessonCol {
	min-height: 75vh;
}

.lessonTabs :global(.tab-content) {
		position: relative;
		flex: 1;
}

.lessonContent {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow-y: auto;
	height: 100%; 
}

@media only screen and (min-height: 500px) {
}
@media print {
	.printHelper { 
		display: block;
		overflow: visible;
		white-space: pre;
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	.lessonContent {
		overflow-y: visible;
	}
}
