.lessonImage {
	max-width: 90%;
	max-height: 500px;
}

label[title="Library"], label[title="Keep selected tool active after drawing — Q"], label[title="Hand (panning tool) — H"] {
	display: none !important;
}

button[title="More tools"], button[title="Zoom out — Cmd+-"], button[title="Reset zoom"], button[title="Zoom in — Cmd++"], button[title="Help — ?"] {
	display: none !important;
}

.lessonImage {
	max-width: 90%;
	max-height: 500px;
}

.canvas {
	margin-top: 1rem;
	border: 0.8px solid #ccc;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	overflow: hidden;
	height: 70vh;
}
