@use "sass:color";
@use "../../../../../scss/variables.scss";

.actionCards {
    background-color: variables.$primary !important;
    color: white;
    font-size: 40px;
    box-shadow: 0 3px 2px 1px rgb(220, 220, 220);
    padding-top: 40px;
    padding-bottom: 40px;
}

.actionCards i {
    font-size: 60px;
}

.actionCards:hover {
    background-color: color.scale(variables.$primary, $lightness: 10%) !important;
    box-shadow: 0 3px 2px 2px rgb(220, 220, 220);
}
