.container {
    padding-top: 25px;
}

.previewTop {
    height: 5%;
}

.previewBottom {
    height: 95%;
}

.header {
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	text-align: center;
	padding-bottom: 25px;
}

.noAccessModuleBtn {
    background-color: gray !important;
}

.submissionList {
    cursor: pointer;
}

.submissionList td {
    text-align: center;
}

.submissionList th {
    text-align: center;
}

.buttonGlasses {
    padding-right: 10px;
}
