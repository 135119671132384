@use "../../../scss/variables.scss";
@import "../../../scss/app.scss";

.heroSection {
    background-size: cover;
    background-position: top;
    padding: 200px 0;
    color: white;
    font-family: "Open Sans", sans-serif;
}

.testimonialSection {
    background-size: cover;
    background-position: center;
    color: white;
}

.planCard {
    padding: 25px 20px 25px 20px !important;
    border-radius: 15px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: $light;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
    min-height: 620px !important;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.classPlan {
    background-color: #3378F6;
    color: white !important;
}

.planType {
    font-size: large;
    font-weight: 600;
}

.planDescription {
    font-size: 14px;
    line-height: 1.5;
    width: 80%;
}

.greyLine {
    border-top: 1px solid #ccc;
    margin: 15px 10px;
}

.whiteLine {
    border-top: 1px solid white;
    color: white;
    margin: 15px 10px;
}

.planCard li {
    list-style: none;
    font-size: 15px;
}

.gap {
    height: 1rem;
}

.planBtn {
    background-image: linear-gradient(to left, variables.$primary, rgb(101, 114, 249));
    text-decoration: none;
    color: white;
    padding: 6px;
    text-align: center;
}
.planBtn:hover {
    color: white;
}

@media (min-width: 768px) {
    .gap {
        height: 4rem;
    }
}

.imgBtn {
    position: relative;
    background-color:transparent !important;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 85%;
    border-color:rgba(2,8,69,0.2) !important;
    width:100%;
}
.imgBtn:hover {
    border: 1px solid lightslategray !important;
    box-shadow: 4px 6px lightslategray !important;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
}
.imgBtn>div{
    margin-top:100%;
    bottom:0; 
}

.planRow {
    display: flex;
    justify-content: center;
}