@use "../../../scss/variables.scss";

.collection {
    border: 1px solid var(--border-color);
    border-radius: 15px;
    padding: 15px;
}

.addSection {
    background-color: #F2F2F2;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-left: -20px;
    margin-right: -20px;
}

.addBottom {
    background: repeating-linear-gradient(
        transparent 0px 54px,
        var(--border-color) 0px 55px
    );
    height: 100%;
}

.greyText {
    color: #797676;
}

.unavailableAssignment {
    background-color: #E0E0E2;
}

.calendar h2 {
    font-weight: bold !important;
}

.calendar a {
    text-decoration: none !important;
    color: black;
}

:root {
    --fc-button-bg-color: #{variables.$primary} !important;
    --fc-button-hover-bg-color: #{variables.$primary} !important;
    --fc-today-bg-color: #d8dbf7 !important;
}

// Ensure user know that calendar event is clickable
table tbody td > div > div > div > a:hover {
    cursor: pointer;
}

.colorLegendBox {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}

.colorLegendText {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem; 
    margin-left: 4px;
}

.noSubmission {
    background-color: #3788D8;
}

.submissions {
    background-color: #198754;
}

.submissionsDue {
    background-color: #23c97c;
}
.noSubmissionDue {
    background-color: red;
}

.labelTagTopic {
    background-color: lightgreen;
    font-weight: bold;
    color: variables.$primary !important;
}

.labelTagLesson {
    background-color: #a3aafa;
    font-weight: bold;
    color: variables.$primary !important;
}

.labelTagAssessment {
    background-color: rgb(223,216,253);
    font-weight: bold;
    color: variables.$primary !important;
}

@media screen and (min-width: 991px) and (max-width: 1337px) {
    .gridContainer {
        display: flex !important;
        flex-direction: column;
    }

    .colorLegendText {
        display: block;
        width: 100% !important;
        text-align: left;
    }

    .item {
        width: 100% !important;
    }
}
