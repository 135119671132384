:root {
    --border-color: #E6E6E6;
    --main-interactions: #3F4BBC;
    --main-text: #2F2E41;
}

.dashboard {
    color: var(--main-text) !important;
}

.sidebarFull {
    width: 15rem;
    height: 100% !important;
    background-color: white;
    transition: 0.2s ease-out;
    border-right: 1px solid var(--border-color);
}

.sidebarMin {
    width: 15rem;
    margin-left: -12rem;
    height: 100%;
    transition: 0.2s ease-out;
    border-right: 1px solid var(--border-color);
}

/* push down side bar (top: 10%) on screen sizes between 280px and 380px */
@media screen and (min-width: 280px) and (max-width: 380px) {
    .sidebarFull {
        position: absolute;
        top: 10%;
        z-index: 1;
    }

    .sidebarContent {
        position: absolute;
        left: 12%;
        z-index: 0;
        width: 90%;
    }
}

/* push up side bar (top: 8%) on screen sizes between 380px and 430px */
@media screen and (min-width: 380px) and (max-width: 430px) {
    .sidebarFull {
        position: absolute;
        top: 8%;
        z-index: 1;
    }

    .sidebarContent {
        position: absolute;
        left: 12%;
        z-index: 0;
        width: 90%;
    }
}

.sidebarDropdown {
    flex: 3;
    max-width: 100%;
}

.sidebarDropdownButton {
    width: 100%;
    min-height: 70px;
    white-space: normal !important;
    box-shadow: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    :focus {
        border-bottom: 2px solid var(--main-interactions) !important;
    }
}

.sidebarDropdownOption {
    font-size: 20px;
}

.sidebarDropdownAddOption:not(:active) {
    color: #797676 !important;
    border-top: 1px solid var(--border-color) !important;
}

.sidebarLinks {
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: left;
}

.sidebarLinks li {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 2em;
}

.collapsedLink {
    padding-left: 12em !important;
}

/* Sidebar links that are not active */
.sidebarLinks li:hover:not(.active) {
    cursor: pointer;
    background-color: var(--border-color);
}
.sidebarLinks a {
    color: inherit;
    text-decoration: inherit;
}
.sidebarLinks a:hover {
    color: inherit;
}

.sidebarLinks li.active {
    color: var(--main-interactions);
    border: 1px solid var(--main-interactions);
}

.sidebarContent {
    flex: 1;
    padding: 0 0.5rem;
    overflow: auto;
}
