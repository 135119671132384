@import "./mixins/inputicon.scss";

.search {
    background-color: #F4F4F4;
    border-radius: 20px;
    @include inputIcon(
        $inputSelector: input,
        $leftMargin: 40px
    )
}
