@use "../../scss/variables.scss";

.background {
    width: 100%;
    height: 100%;
    background: url('rotated-logo.png') no-repeat;
    background-color: #f0f0f0;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
}