@use "../../scss/variables.scss";

.background {
    background-image: url('STEM-background-1.png');
    height: 100vh !important;
}

.progressBar {
    height: 8px;
}

.input {
    border-radius: 10px !important;
    -webkit-appearance: none;
    width: 100%;
}

.label {
    text-align: left !important;
}

.quizContainer {
    width: 75%;
}

.studentQuizContainer {
    width: 65%;
}

.radioBtn {
    width: 6rem;
}

.termBgUnselect {
    background-color: #a3aafa;
    font-weight: normal;
}

.termBgSelect {
    background-color: variables.$theme-color-2;
    font-weight: bold;
}

.rocketIcon {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

@media screen and (max-width: 460px) {
    .quizContainer {
        width: 100%;
    }
    .studentQuizContainer {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .termsContainer {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .radioBtn {
        flex-basis: 30% !important;
    }
}

@media screen and (max-width: 576px) {
    .quizPurpose {
        font-size: 14px;
    }
}
