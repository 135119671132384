
.graph {
    path, line, rect:not(.slot) {
        stroke: #CCD2D5;
        stroke-width: 1;
    }
}
.stickyCol {
    position: sticky;
    left: 0;
    background-color: white !important;
    border-right: solid 1px #111; 
}

