@use "../../scss/variables.scss";

.background {
    background-image: url("./STEM-background-2.png");
}

.planCard {
    padding: 25px 20px 25px 20px !important;
    background-color: #EDEEFE;
    border-radius: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.classPlan {
    background-color: #3378F6;
    color: white !important;
}

.planType {
    font-size: medium;
}

.planDescription {
    font-size: 14px;
    line-height: 1.5;
    width: 80%;
}

.greyLine {
    border-top: 1px solid #ccc;
    margin: 15px 10px;
}

.whiteLine {
    border-top: 1px solid white;
    color: white;
    margin: 15px 10px;
}

.planCard li {
    list-style: none;
    font-size: 15px;
}

.gap {
    height: 1rem;
}

.planBtn {
    background-image: linear-gradient(to left, variables.$primary, rgb(101, 114, 249));
    text-decoration: none;
    color: white;
    padding: 6px;
    text-align: center;
}
.planBtn:hover {
    color: white;
}

@media (min-width: 768px) {
    .gap {
        height: 4rem;
    }
}
  