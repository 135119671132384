.background {
    background-image: url("./STEM-background\ 1.png");
    width: 100% !important;
    height: 100vh !important;
}

.sendEmailBtn {
    width: 25%;
}

@media screen and (max-width: 768px) {
    .sendEmailBtn {
        width: 50%;
    }
}

@media screen and (max-width: 576px) {
    .sendEmailBtn {
        width: 75%;
    }
}
