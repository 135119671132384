.commentTextbox {
    border-radius: 8px;
    border: 1px solid #CCD2D5;
}

.assessmentGradeContainer {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}

.studentDrawingAnswerImage {
    max-width: 100%;
}

.lessonGradeContainer {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}

@media screen and (min-width: 768px) {
    .assessmentGradeContainer {
        position: sticky;
        top: 12%;
        height: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .lessonGradeContainer {
        position: sticky;
        top: 8%;
        height: 100% !important;
    }
}
