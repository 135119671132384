.studentGrade {
    background-color: #E0E0E2;
}

.bgLightBlue, .bgLightBlue>button {
    background-color: rgba(#3F4BBC80,0.09) !important;
    height: 100% !important;
}

.assignmentOverview:hover {
    border: 1px solid lightslategray !important;
    box-shadow: 4px 6px lightslategray !important;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
}

.assignmentTitle {
    font-size: large !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .dueStatus {
        font-size: small;
    }
}

@media screen and (max-width: 768px) {
    .dueDateContainer {
        margin-top: 0.6rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .assignmentTitle {
        font-size: medium !important;
    }
}

@media screen and (max-width: 992px) {
    .calendarContainer {
        margin-top: 1rem !important;
    }
    .assignmentTitle {
        margin-bottom: 0.3rem !important;
    }
}
