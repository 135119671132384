@use "../../../../scss/variables.scss";
@import "../../../../styles/inputIcon.scss";

.dropdowns {
    border: 1px solid;
    border-color: variables.$primary;
    color: variables.$primary;
    border-radius: 5px;
    select {
        color: variables.$primary;
    }
}
