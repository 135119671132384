
.reactTable table {
    margin-top: 10px;
}
.reactTable th {
    border: 0 !important;
    align-items: center;
}
.reactTable th:not(:first-child):not(:nth-child(2)) {
    display: flex !important;
    justify-content: center !important;
}
.reactTable tbody tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
}
.reactTable table {
    --bs-table-striped-bg: transparent;
}


