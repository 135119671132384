@use "variables";

$enable-negative-margins: true !default;

$theme-color: variables.$theme-color;
$theme-color-2: variables.$theme-color-2;
/*
*  Bootstrap Customization
*/
$primary: $theme-color-2;
$light: rgba(245, 245, 252, 0.98);
//pagination
$pagination-color: #000;
$pagination-focus-color: #000;
$pagination-hover-color: #000;
$pagination-active-color: #fff;
$pagination-active-bg: $theme-color;
$border-radius: .35rem;

//accordion
$accordion-button-active-bg: white;
$accordion-button-active-color: black;
$accordion-button-padding-y: .9rem;

@import "bootstrap/scss/bootstrap";

//https://github.com/twbs/bootstrap/blob/ef5f3a8a1327bd294e37d65f5436225ada26c8e8/scss/mixins/_buttons.scss#L7
.btn-default {
	@include button-variant(
			$background: #ffffff,
			$border: #ccc,
			$color: #333333,
			$hover-background: $theme-color,
			$disabled-background: #85807f,
			$disabled-color: #ffffff
	);
}

.btn-theme {
	@include button-variant(
			$background: $theme-color-2,
	$border: #ccc,
			$color: white
	);
}

.btn-outline-theme {
@include button-outline-variant(
	$color: $theme-color-2
)
}

body {
	font-family: 'Open Sans', Arial, sans-serif;
	text-align: center;
}
body.full-screen-mode {
  height: 100vh;
  overflow: hidden !important;
  padding-right: 15px;
}

footer {
	padding-bottom: 10px;
}

footer, .social-links {
	margin-bottom: 1em;
	font-size: 10px;
}

footer .col-sm-6
{
	padding-left: 2.5px;
	padding-right: 2.5px;
}

footer a {
	text-decoration: none;
	color: #337ab7;
}

footer hr {
	color: #eee;
	opacity: 1;
	margin-top: 20px;
	margin-bottom: 20px;
}

footer p {
	margin-bottom: 10px;
}

.social-links img {
	width: 25px;
	height: 25px;
	border-radius: 3px;
}
.social-links a:hover img {
	box-shadow:	 0px  1px 1px rgba(160, 160, 160, 0.5),
				 0px -1px 1px rgba(160, 160, 160, 0.5),
				-1px  0px 1px rgba(160, 160, 160, 0.5),
				 1px  0px 1px rgba(160, 160, 160, 0.5);
}

@media print {
	/*Remove header and footer*/
	@page { margin: 0; }
  body { margin: 1.6cm; }
}

/* handle inner accordions */
.accordion .accordion button {
	font-size: 15px;
}

/* In medium screen and above, full height will be applied to make only a portion of lessons search screen scrollable. */
@media (min-width: 768px) {
	.full-screen-md  {
		height: calc(100vh - 80px);
	}
}

/* Increase navbar height by 30px to fit image */
.navbar {
	font-size: 14px;
	z-index: 1030;	
}

.nav-link {
	color: #333 !important;
	>i {
		font-size: 1.4rem;
		padding: 0;
		color: $theme-color-2;
	}
}

.nav-item {
	padding: 0px 4px;
}

.header {
	margin-bottom: 25px;
	font-weight: 300;
	text-align: center;
	padding-bottom: 25px;
	cursor: default;
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: #BD1900;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.padding-header {
	padding-top: 80px;
}

#google_translate_element {
	font-size: 10px default;
	text-align: right;
	margin-left: 3px;
	.goog-te-gadget {
		font-size: 10px;
	}
	.goog-te-combo {
		display: block;
		appearance: none;
		background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%;
		background-color: transparent;
		background-repeat: no-repeat;
		font-size: 14px;
		border: none;
		color: #333;
		margin-left: 5px;
	}
}
.VIpgJd-ZVi9od-ORHb-OEVmcd { /* google header popup that shows when clicking translate button */
	top: 80px !important;
	box-shadow: none !important;
}

/* Image needs to be fixed-size for absolute centering to work */
.navbar-image img {
	width: 60px;
	height: 60px;
}

/* Override default styling which puts edge of navbar off right edge of screen */
/* Undo left button separation when in collapsed navbar */
.navbar-right, .collapse.navbar-collapse.in .navbar-left .navbar-btn, .collapsing.navbar-collapse .navbar-left .navbar-btn
{
	margin-right: 0px;
}


/* Separate left button(s) from other navbar links */
.navbar-left .navbar-btn
{
	margin-right: 20px;
}

/* Separate right button(s) from other navbar links */
.navbar-right .navbar-btn
{
	margin-left: 20px;
}

/* Undo right button separation when in collapsed navbar */
.collapse.navbar-collapse.in .navbar-right .navbar-btn, .collapsing.navbar-collapse .navbar-right .navbar-btn
{
	margin-left: 0px;
}

.navbar-btn
{
	width: fit-content;
	width: -moz-fit-content;
}

// Landing page Carousel can't be moved to a css module because the inner classes aren't configurable
#landingCarousel {
	height:400px; 
	text-align: center;
	color:white;
	background-position: center;
	background-size:cover;
}
#landingCarousel .carousel-inner {
	height:100%;
	background-color:rgba(40, 51, 172, 0.65);
	display: flex;
	align-items: center;
}
#landingCarousel p {
	margin-left:10%;
	margin-right:10%;
	margin-bottom: 2.5rem;
}
#landingCarousel p b {
font-style: italic;
font-weight: 900;
font-size: calc(1.5rem + 0.3vw)
}
#landingCarousel .carousel-control-prev, .carousel-control-next {
	width:10%; 
}

@media only screen and (max-width: 992px)
{

	#landingCarousel {
		font-size: 13px;
	}
	#landingCarousel h4 {
		font-size: 16px;
		line-height:1.5rem;
	}
}
