#loginPage {
    .form-container {
        min-width: 350px;
        padding: 40px;
        background: white;
        margin-top: 50px;
        
        .label {
            font-weight: bold;
            text-align: left;
            color: black;
            
            &.required::after {
                content: '*';
                color: red;
            }
        }
        
        .faded-line {
            flex-grow: 1;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.3);
            margin: 0 10px;
        }
        
        .btn-primary {
            background-color: #3F4BBC;
            border-color: #3F4BBC;
        }
        
        .smaller-font {
            font-size: 0.8rem;
        }
        
        a {
            color: #3F4BBC;
            text-decoration: none;
        }
    }
    .planets {
        max-width: 800px;
    }
    .google-button {
        background-color: #ffffff;
        border: 1px solid #b5b5b8;
        color: #111;
        font-family: Roboto,sans-serif !important;
        font-size: 16px;
    }
    .google-button:hover {
        background-color: #e6e6e6;
    }
    .forgot-password-link {
        color: lightblue;
    }
}
