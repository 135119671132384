
.submissionPagination > * {
    padding-inline: 15px;
}

.wideButton {
    width: 125px;
}

.correctIcon {
    color: green;
    cursor: pointer;
}

.incorrectIcon {
    color: red;
    cursor: pointer;
}

.commentGradeSpan {
    padding-inline: 15px;
}

.commentGrade {
    margin-right: 5px;
    width: 3rem;
}

.commentTextbox {
    border-radius: 8px;
    border: 1px solid #CCD2D5;
}

.overallGradeInput {
    display: inline;
    width: 6rem;
    background-color: #F4F4F4;
    border-radius: 20px;
    border: 0;
    padding-left: 10px;
}

.standardsMasterLabel {
    font-size: 14px;
}

.tableAnswer {
    max-width: 100%;
}

.lessonGradeContainer {
    margin-bottom: 1rem;
}

.assessmentGradeContainer {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

@media screen and (min-width: 992px) {
    .lessonGradeContainer {
        position: sticky;
        top: 0;
        height:  100vh !important;
    }

    .assessmentGradeContainer {
        position: sticky;
        top: 0;
        height:  100% !important;
    }
}


@media screen and (max-width: 697px) {
    .wideButton {
        width: 100px;
    }
}

@media screen and (min-width: 991px) and (max-width: 1025px) {
    .wideButton {
        width: 100px;
    }

    .submitGradeBtnText {
        font-size: 1rem;
    }
}
