.selectable {
    cursor: pointer;
}

.selectable:hover {
	transition: box-shadow ease-in-out 0.2s;
	z-index: 10;
	box-shadow: 0 0.4rem 0.8rem rgb(0 0 0 / 20%) !important;
}

.scrollableOverflow {
	max-height: 80vh;
	overflow: scroll;
}
