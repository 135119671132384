.headerImage {
    max-width: 100%;
}

.studentInfo {
    text-align: center;
}

.studentInfo label {
    font-weight: 700;
}

.questionInput {
    height: 10%;
    width: 100%;
}

.table {
    min-width: 100%;
}

.tableCell {
    border-width: 1px;
}

.tableInput {
    width: 100%;
}

.list {
    list-style-type: none;
    text-align: left;
}
