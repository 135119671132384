/*
* Positions an Icon inside an input field
*/

@mixin inputIcon($inputSelector, $leftMargin) {
    position: relative;

    #{$inputSelector} {
        padding-left: $leftMargin;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
    }
    #{$inputSelector}:focus {
        background-color: rgba(0, 0, 0, 0);
    }

    i {
        position: absolute;
        left: 10px;
        // Maybe a better way to calculate the center of the button
        top: 10px;
    }
}
