.btn-light {
    height:200px; 
}
#landingPage h1, #landingPage h2, #landingPage h3 {
    color: rgb(2,8,69); 
}
.box {
    position: relative;
    padding-bottom: 35px; 
    padding-top: 45px; 
    margin:10px; 
    border: 1px solid rgba(10,10,10,0.8);
    text-align:center; 
    max-width: 420px;
}
.box p  {
    height:155px;
}
.box > i {
    position: absolute;
    top:0;
    left:50%;
    transform: translate(-50%, 0);
    margin-top:-34px;
    padding:14px;
    border-radius:50%; 
}
#overlayLandingTitle {
    justify-content:start;
    padding-top: 30px; 
    z-index: 1;
}
.btn120 {
    width:120px;
}
.greyBox {
    background-color: #E6E6E6;
}
#landingCover {
    margin-left:-7vw;
    margin-top:-1vw;
    padding-left:10px;
    padding-bottom:100px;
    padding-top: 30px;
}
.greyishBg {
    padding:4rem;
    background-color: rgb(250,250,250);
}
.logos {
    display: flex;
    flex-direction: column;
    background-size:cover;
}
.logos img {
    padding:15px;
}
@media only screen and (min-width: 1400px) {
	#landingPage {
		font-size:1.2vw;
	}
}

@media only screen and (min-width: 1900px) and (max-width: 3000px) {
    .box {
        max-width: 600px;
    }
	.box p  {
        font-size: 1vw;
        height: 8.5vw;
    }
}
@media only screen and (min-width: 3001px) and (max-width: 4000px) {
    .box {
        max-width: 800px;
    }
	.box p  {
        font-size: 1vw;
        height: 9vw;
    }
}

@media only screen and (max-width: 767px) {
	#landingCover { 
        padding-left:15px;
        padding-bottom: 40px;
        margin-left:-1vw;
	}
    #overlayLandingTitle {
        margin-left:-45px;
        padding-top: 5px;
    }
}
@media only screen and (max-width: 575px) {
    #landingPage {
		font-size:17px; /* root font size */
	}
	#landingCover { 
        margin:auto;
		display: block;
	}
	#overlayLandingTitle {
        margin:auto;
		position: static;
        text-align: center !important;
	}
    #overlayLandingTitle>div{
        justify-content: center;
    }
}
.imgBtn {
    position: relative;
    background-color:transparent !important;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 85%;
    border-color:rgba(2,8,69,0.2) !important;
    width:100%;
}
.imgBtn:hover {
    border: 1px solid lightslategray !important;
    box-shadow: 4px 6px lightslategray !important;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
}
.imgBtn>div{
    margin-top:100%;
    bottom:0; 
}
.maroonBg {
    color:white; 
    background-color: rgb(113,15,15);
}
.blueBg {
    color:white; 
    background-color: rgb(63,75,188);
}
.navyBg {
    color:white; 
    background-color: rgb(2,8,69);
}
.colHeader {
    padding-bottom:1.5rem; 
}
@media only screen and (max-width: 575px) {
    .colHeader {
        position: absolute;
        top:-35px;
        left:0;
        width:100%;
    }
}
