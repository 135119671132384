@use "../../scss/variables.scss";

.assessment {
  padding: 20px;

  .question {
    margin-bottom: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    background-color: #f5f5f5;

    .text {
      text-align: left;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .answerOption {
      margin: 5px 0;
      background-color: #f5f5f5;
      font-size: "16px"

      input {
        margin-right: 5px;
      }
    }

    .correctAnswer {
      font-size: 16px;
      text-align: left;
    }

    .edit-btn {
      padding: 10px;
      border: none;
      background: none;
      color: variables.$primary;
    }
  }
}
