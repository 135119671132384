@use "../../scss/variables.scss";

.container {
    width: 75% !important;
    margin: auto !important;
    margin-top: 25px !important;
}

.tab {
    border: 1px solid lightslategray !important;
    box-shadow: 2px 3px lightslategray !important;
}

.tab:hover {
    box-shadow: 4px 5px lightslategray !important;
    transition: box-shadow 0.1s ease-in-out;
}

.activeTab {
    border: none;
    box-shadow: 4px 5px lightgray !important;
}

@media screen and (max-width: 768px) {
    .backgroundTable {
        width: 100% !important;
        overflow-x: auto !important;
        display: block;
    }
}

@media screen and (max-width: 568px) {
    .container {
        width: 90% !important;
        margin: auto !important;
        margin-top: 30px !important;
    }
}

.subscribeBtn {
    position: absolute;
    right: 15%;
}

@media screen and (max-width: 743px) {
    .subscribeBtn {
        position: static;
    }
}

/* target ipad mini and air */
@media screen and (min-width: 744px) and (max-width: 834px) {
    .subscribeBtn {
        position: absolute;
        right: 10%;
    }
}

.bgDesaturated {
    background-color: variables.$desaturate-color;
}

.planningIcon {
    width: 50px;
}

.walkthroughVideo {
    width: 700px;
    height: 393.75px;
}

.lessonItem:hover {
    background-color: lightgray;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .walkthroughVideo {
        width: 400px;
        height: 225px;
    }
}

@media screen and (max-width: 576px) {
    .walkthroughVideo {
        width: 320px;
        height: 180px;
    }
}

@media screen and (max-width: 375px) {
    .walkthroughVideo {
        width: 320px;
        height: 168.75px;
    }
}
